html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img,
embed,
object,
video {
  height: auto;
  max-width: 100%;
}

audio {
  max-width: 100%;
}

iframe {
  border: 0;
  max-width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
  text-align: left;
}

.c_gdpr {
  position: sticky;
  z-index: 99999;
  bottom: 0;
  color: #fff;
  background-color: #3c424f;
}

.c_gdpr button {
  background-color: #fff;
  padding: 9px 36px;
  padding: 0.5rem 2rem;
  font-size: 13.5px;
  font-size: 0.75rem;
}

.c_gdpr a {
  color: #fff;
  text-decoration: underline;
}

.c_gdpr .info {
  padding: 9px 14.4px;
  padding: 0.5rem 0.8rem;
  font-size: 12.6px;
  font-size: 0.7rem;
}

html {
  visibility: visible;
}

body .c_gdpr button {
  font-weight: bold;
}

main .c_form .c_form_search_filters_selected .tag {
  background: #00000047;
  border-radius: 5px;
  margin: 15px 0 0 5px;
  padding: 2px 9px;
  font-size: 16px;
}

main .c_form .c_form_search_filters_selected .remove .tag {
  min-width: 175px;
  background-color: #caa274;
}

main .c_form .c_form_search_filters_selected .tag .c_icon {
  width: 18px;
  height: 18px;
  margin-right: 9px;
  margin-right: 0.5rem;
}

main .c_form .c_form_search_filters_selected .tag a {
  white-space: nowrap;
}

* {
  font-family: Montserrat, sans-serif;
}

html {
  font-size: 112.5%;
}

body {
  color: #3c424f;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #3c424f;
}

table {
  width: 100%;
}

td,
th {
  vertical-align: text-top;
  padding: 9px 9px 0px 0px;
  padding: 0.5rem 0.5rem 0rem 0rem;
}

td:last-child,
th:last-child {
  padding: 0;
}

p,
li {
  font-size: 16px;
  padding-top: 8px;
  margin-bottom: 20px;
  line-height: 26px;
  letter-spacing: 0;
}

li {
  margin: 0;
  padding: 0;
}

.content a {
  text-decoration: underline;
}

.content li:before {
  content: "○ ";
  margin-left: -11px;
  color: #caa274;
}

.content li > p:first-child {
  display: inline;
}

.content ul,
.content ol {
  list-style-type: none;
  padding-top: 8px;
  padding-left: 8px;
}

.heading,
.heading > * {
  font-size: 32px;
  padding-top: 8px;
  margin-bottom: 20px;
  line-height: 42px;
  font-family: "Cormorant Infant", sans-serif;
  font-weight: 600;
  hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 0;
}

p {
  white-space: pre-wrap;
}

p:last-child {
  margin-bottom: 0;
}

.txt_1,
.txt_1 > *,
h1 {
  font-size: 36px;
  padding-top: 8px;
  margin-bottom: 20px;
  line-height: 42px;
}

.txt_2,
h2 {
  font-size: 27px;
  padding-top: 4px;
  margin-bottom: 24px;
  line-height: 28px;
}

.txt_3,
h3,
.txt_3 > p,
.txt > h3 {
  font-size: 24px;
  padding-top: 6px;
  margin-bottom: 22px;
  line-height: 28px;
}

.txt_4,
h4 {
  font-size: 24px;
  padding-top: 6px;
  margin-bottom: 22px;
  line-height: 28px;
}

.txt_5,
h5 {
  font-size: 24px;
  padding-top: 6px;
  margin-bottom: 22px;
  line-height: 28px;
}

.txt_6,
h6 {
  font-size: 24px;
  padding-top: 6px;
  margin-bottom: 22px;
  line-height: 28px;
}

.auto {
  width: 100%;
  max-width: 1140px;
  margin: auto;
}

@font-face {
  font-display: swap;
  font-family: Cormorant Infant;
  font-style: normal;
  font-weight: 400;
  src: url("assets/font/cormorant-infant-v16-latin-regular.woff");
}

@font-face {
  font-display: swap;
  font-family: Cormorant Infant;
  font-style: normal;
  font-weight: 600;
  src: url("assets/font/cormorant-infant-v16-latin-600.woff");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: url("assets/font/montserrat-v14-latin-300.woff");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url("assets/font/montserrat-v14-latin-600.woff");
}

main {
  background-color: #fff;
  padding-bottom: 0px;
}

body .c_remember .label {
  padding-right: 18px;
  padding-right: 1rem;
}

body .c_remember .counter {
  border: 2px solid #3c424f;
  border-radius: 27px;
  border-radius: 1.5rem;
  width: 27px;
  width: 1.5rem;
  height: 27px;
  height: 1.5rem;
  margin-top: -2px;
  text-align: center;
}

main .cm_3 .heading,
main .cm_3 .content {
  text-align: center;
}

main .cm_4 .heading,
main .cm_4 .content {
  text-align: center;
}

main > .auto,
.e_data_ids_gamma > .auto,
main > * > .auto {
  padding-left: 18px;
  padding-left: 1rem;
  padding-right: 18px;
  padding-right: 1rem;
}

footer .cm_form {
  margin: 0;
}

main .c_arrows {
  border-width: 0px 1.8px 1.8px 0px;
  border-width: 0rem 0.1rem 0.1rem 0rem;
}

main .c_pagination {
  padding-bottom: 18px;
  padding-bottom: 1rem;
}

main .c_pagination > * {
  padding-right: 9px;
  padding-right: 0.5rem;
}

main .c_field.filled .label {
  left: 12px;
}

main .c_form .c_field {
  padding: 0;
}

main .c_form_array {
  border: 1px solid white;
}

main .c_form button {
  padding: 10.799999999999999px 18px;
  padding: 0.6rem 1rem;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  background-color: #eac25c;
  color: #65666a;
  border-width: 2px;
  border-style: solid;
  border-color: #65666a;
  cursor: pointer;
}

main .c_form button:hover {
  background-color: #fff;
  color: #caa274;
  border-width: 2px;
  border-style: solid;
  border-color: #caa274;
}

main .action_button {
  padding-bottom: 18px;
  padding-bottom: 1rem;
}

main .action_button .button {
  border: 1px solid white;
  white-space: nowrap;
  padding: 9.9px 36px;
  padding: 0.55rem 2rem;
  background-color: #00000047;
  display: block;
  text-align: center;
  color: #fff;
  font-weight: 600;
}

main .c_form .c_form_search {
  width: 100%;
  padding: 0px 0px 18px 0px;
  padding: 0rem 0rem 1rem 0rem;
}

main .c_form .group > .c_form_string {
  width: 100%;
  margin: 0px 0px 18px 0px;
  margin: 0rem 0rem 1rem 0rem;
}

main .c_form_search .c_form_string {
  min-width: 198px;
  min-width: 11rem;
}

main .c_form_search .box {
  padding: 0;
  width: 100%;
}

main .c_form_search .c_icon {
  margin-right: -22.5px;
  margin-right: -1.25rem;
  height: 18px;
  height: 1rem;
  width: 18px;
  width: 1rem;
  z-index: 99;
}

main .c_form_search .c_field.active .label,
main .c_form_search .c_field.filled .label {
  left: 15px;
  top: 0;
}

.c_form_string.c_field.active .label,
.c_form_string.c_field.filled .label {
  left: 15px;
  top: 0;
}

.c_form_string.c_field .label {
  top: 11px;
}

main .c_form_search .c_field .label {
  left: 40px;
  top: 11px;
}

main .c_form_checkbox.c_field label {
  padding-right: 45px;
  padding-right: 2.5rem;
}

main .c_form_checkbox input {
  margin-right: 9px;
  margin-right: 0.5rem;
}

main .c_form_checkbox_list.c_field_group .list label {
  font-size: 18px;
  padding-top: 8px;
  /* margin-bottom: 20px; */
  line-height: 28px;
}

main .c_form_checkbox_list legend {
  font-size: 24px;
  padding-top: 6px;
  margin-bottom: 22px;
  line-height: 28px;
}

main .c_form_checkbox_list_tree legend {
  font-size: 24px;
  padding-top: 6px;
  margin-bottom: 22px;
  line-height: 28px;
}

main .c_form_checkbox_list_tree > div > div > .c_form_checkbox label {
  font-weight: 600;
}

main .c_form_search input[type="text"] {
  padding-left: 36px;
  padding-left: 2rem;
  border-color: #fff;
}

main .c_form_search_filters .label {
  font-size: 24px;
  padding-top: 6px;
  margin-bottom: 22px;
  line-height: 28px;
}

main .c_form_search_filters .button {
  margin-left: 18px;
  margin-left: 1rem;
  background-color: #65666a;
  color: #fff;
  padding: 10.799999999999999px 18px;
  padding: 0.6rem 1rem;
  font-weight: 300;
  text-transform: uppercase;
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  border-color: #65666a;
  text-align: center;
}

main .c_form_search_filters .button:hover {
  background-color: #fff;
  color: #caa274;
  border-width: 2px;
  border-style: solid;
  border-color: #caa274;
}

main .c_form .group {
  background-color: #00000047;
  padding: 18px 18px 18px 18px;
  padding: 1rem 1rem 1rem 1rem;
}

main .cm_form .c_form .group {
  padding: 0;
}

main .cm_form .c_form .group > .c_form_string {
  margin: 0;
}

main .cm_form .c_form > * {
  margin-bottom: 18px;
  margin-bottom: 1rem;
}

main .cm_form .row .button {
  background-color: #fff;
  color: #3c424f;
}

main .c_form .virtual.group {
  box-shadow: 0px 10px 10px -7px rgb(0 0 0 / 50%);
  background: #fff;
  padding: 18px 18px 18px 18px;
  padding: 1rem 1rem 1rem 1rem;
}

main .c_form .c_virtual.c_field .label {
  color: #fff;
}

.c_3 .c_form .actions {
  display: none;
}

main .c_form .c_virtual {
  border: 1px solid white;
  white-space: nowrap;
  position: relative;
  width: 100%;
  height: 45px;
  height: 2.5rem;
  margin: 0px 0px 18px 0px;
  margin: 0rem 0rem 1rem 0rem;
}

main .c_form .c_virtual i {
  position: absolute;
  right: 9px;
  right: 0.5rem;
}

main .c_form .c_form_array {
  background-color: #fff;
}

main .c_form .c_form_array .label {
  position: relative;
  line-height: 45px;
  line-height: 2.5rem;
  top: 0;
  left: 0;
}

main .c_form .c_form_array.active .label {
  position: absolute;
  top: -17px;
}

main .c_form .c_form_array .c_arrows {
  border-color: #3c424f;
}

main .c_form .c_arrows {
  right: 9px;
  right: 0.5rem;
  top: 18px;
  top: 1rem;
  position: absolute;
  border-color: #fff;
}

main .c_11 ul.row > li {
  height: 36px;
  height: 2rem;
}

main .cm_12 .c_11 ul.row {
  flex-direction: column;
}

main .cm_12 .actions {
  display: none;
}

main .c_slider picture > img.hero {
  min-height: auto;
}

main .c_4 .slider-nav {
  top: 32%;
}

main .slider-nav {
  position: absolute;
  top: 42%;
  width: 100%;
  font-weight: bold;
  font-size: 24px;
  color: #fff;
}

main .slider-left,
main .slider-right {
  background-color: #54595f;
  padding: 5px;
  cursor: pointer;
}

@media (min-width: 1280px) {
  main .c_form_checkbox_list_tree .l2 {
    width: 33%;
  }

  main > .auto,
  .e_data_ids_gamma > .auto,
  main > * > .auto {
    padding-left: 0;
    padding-right: 0;
  }

  main .c_form_checkbox_list.c_field_group .list {
    display: flex;
    flex-wrap: wrap;
  }

  main .c_form_checkbox_list.c_field_group .list > * {
    width: 33%;
  }
}

@media (min-width: 840px) {
  main .c_form .c_form_search {
    padding: 0px 0px 18px 0px;
    padding: 0rem 0rem 1rem 0rem;
    margin-right: 9px;
    margin-right: 0.5rem;
  }

  main .c_form .group {
    padding: 18px 18px 0px 18px;
    padding: 1rem 1rem 0rem 1rem;
  }

  main .c_form .c_virtual,
  main .c_form .group > .c_form_string {
    margin: 0px 9px 18px 0px;
    margin: 0rem 0.5rem 1rem 0rem;
  }
}

.c_toast {
  visibility: hidden;
  min-width: 252px;
  min-width: 14rem;
  margin-left: -126px;
  margin-left: -7rem;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 18px;
  padding: 1rem;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 36px;
  bottom: 2rem;
}

.c_toast.show {
  visibility: visible;
  box-shadow: 0px 3px 20px 0px #0000005e;
  animation: fadeout 0.5s, fadein 0.5s;
}

@media (min-width: 70rem) {
}

@keyframes fadein {
  from {
    bottom: 0;
  }

  to {
    bottom: 36px;
  }
}

@keyframes fadeout {
  from {
    bottom: 36px;
  }

  to {
    bottom: 0;
  }
}

.nav-collapse ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  list-style: none;
}

.nav-collapse li {
  width: 100%;
  display: block;
}

.nav-collapse {
  clip: rect(0 0 0 0);
  max-height: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1;
}

.nav-collapse.opened {
  max-height: 9999px;
}

.nav-toggle {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@media screen and (min-width: 1024px) {
  .js .nav-collapse {
    position: relative;
  }

  .js .nav-collapse.closed {
    max-height: none;
  }

  .nav-toggle {
    display: none;
  }
}

.c_hamburger {
  position: relative;
  display: inline-block;
  width: 1.25em;
  height: 0.8em;
  margin-right: 0.3em;
  border-top: 0.2em solid #fff;
  border-bottom: 0.2em solid #fff;
  box-sizing: initial;
}

.c_hamburger:before {
  content: "";
  position: absolute;
  top: 0.3em;
  left: 0px;
  width: 100%;
  border-top: 0.2em solid #fff;
}

.center {
  display: flex;
  justify-content: center;
}

.center_v {
  display: flex;
  align-items: center;
}

.center_vh {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c_header .nav-collapse {
  overflow: visible;
  background-color: #fff;
  padding-left: 9px;
  padding-left: 0.5rem;
  z-index: 100;
}

.nav-collapse ol {
  padding: 0;
}

.nav-collapse > ol {
  padding: 9px 18px 18px 18px;
  padding: 0.5rem 1rem 1rem 1rem;
  justify-content: flex-end;
}

.nav-collapse > ol > li > a {
  color: #54595f;
  display: block;
}

.nav-collapse > ol > li > ol > li > a {
  display: block;
  padding: 0px 18px 0px 36px;
  padding: 0rem 1rem 0rem 2rem;
  background-color: #fff;
}

.nav-collapse > ol > li > ol > li > ol > li > a {
  display: block;
  padding: 0px 18px 0px 54px;
  padding: 0rem 1rem 0rem 3rem;
}

.nav-collapse .active ol {
  display: block;
}

.nav-collapse ol ol {
  padding: 0;
}

.nav-collapse .c_arrows {
  float: right;
  margin-top: 12px;
}

.nav-collapse .c_arrows.right {
  margin-right: 5px;
}

.nav-collapse i {
  display: none;
}

.nav-toggle {
  margin: 18px 0px 18px 18px;
  margin: 1rem 0rem 1rem 1rem;
  width: 80px;
  position: absolute;
  right: 18px;
  right: 1rem;
}

.nav-toggle .icon {
  height: 25px;
  width: 25px;
  margin-right: 0.5rem;
}

@media (min-width: 841px) {
}

@media (min-width: 1024px) {
  .header_main .navigations {
    width: auto;
    padding-top: 0;
    align-items: baseline;
    position: absolute;
    top: 1rem;
    left: 10rem;
  }

  .header_main.row,
  .navigations.row {
    flex-direction: row;
  }

  .c_header .nav-collapse {
    background-color: initial;
  }

  .nav-collapse ol {
    padding: 0;
  }

  .nav-collapse > ol {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .nav-collapse ol ol {
    padding: 0;
    display: block;
  }

  .nav-collapse li {
    width: auto;
  }

  .nav-collapse li a {
    white-space: nowrap;
  }

  .nav-collapse .c_arrows {
    display: none;
  }

  .nav-collapse > ol > li > a {
    padding: 0px;
    padding: 0rem;
  }

  .nav-collapse > ol > li > ol > li > a {
    padding: 0px;
    padding: 0rem;
    background-color: #fff;
  }

  .nav-collapse > ol > li > ol > li > ol > li > a {
    padding: 0px;
    padding: 0rem;
  }

  .nav-collapse > ol > li {
    padding-right: 36px;
    padding-right: 2rem;
  }

  .nav-collapse > ol > li > ol {
    display: none;
    z-index: 1;
    background: #fff;
  }

  .nav-collapse > ol > li:hover {
    border-bottom-color: #caa274;
  }

  .nav-collapse > ol > li:hover > ol {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 28px;
    padding: 18px;
    padding: 1rem;
    box-shadow: 0 32px 60px rgba(0, 0, 0, 0.1);
  }

  .nav-collapse > ol > li:hover > ol > li {
    padding-right: 18px;
    padding-right: 1rem;
    margin-right: 18px;
    margin-right: 1rem;
  }

  .nav-collapse > ol > li:hover > ol > li:last-child {
    padding: 0;
    border: 0;
    margin: 0;
  }
}

.c_dropdown {
  position: relative;
  width: 100px;
}

.c_dropdown ul {
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 9;
}

.c_dropdown li {
  background-color: #fff;
  border-bottom: 1px solid #dfdfdf;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  margin-right: -2px;
  text-align: left;
}

.c_dropdown li a {
  padding: 0 0 0 14px;
}

.nav-toggle {
  width: 80px;
  margin: 16px 0 0 16px;
}

.c_hamburger {
  border-top-color: #222;
  border-bottom-color: #222;
}

.navigations {
  padding-top: 54px;
  padding-top: 3rem;
  flex-direction: column;
}

.c_hamburger:before {
  border-top-color: #222;
}

.breadcrumb {
  display: none;
}

.c_toast {
  border-radius: 9px;
  border-radius: 0.5rem;
  background-color: #caa274;
}

.c_toast.show a {
  line-height: 0px;
}

.c_toast.show img {
  width: 38px;
  height: 38px;
}

.header_main .logo {
  width: 165px;
  height: 45px;
}

.header_main > .logo_container {
  position: absolute;
  top: 9px;
  top: 0.5rem;
  left: 18px;
  left: 1rem;
  width: 165px;
}

.header_main {
  position: relative;
  height: 60px;
}

.c_header .navigations_aside {
  justify-content: space-between;
  padding: 9px 9px 0px 9px;
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  z-index: 9;
}

.c_header .c_dropdown ul {
  width: 190px;
}

.c_header .c_dropdown li {
  padding: 9px;
  padding: 0.5rem;
}

.c_header .actions {
  display: none;
}

.c_header .c_form .search_ref {
  margin-left: -27px;
  margin-left: -1.5rem;
  z-index: 8;
}

.c_header .c_form .search_ref .c_icon {
  width: 18px;
  width: 1rem;
  height: 18px;
  height: 1rem;
  position: absolute;
  top: 21px;
}

.c_header .c_form .c_field {
  padding: 0;
}

.c_header .c_form {
  margin: 0px 18px 0px 0px;
  margin: 0rem 1rem 0rem 0rem;
}

@media (min-width: 1024px) {
  .c_header .navigations_aside {
    justify-content: flex-end;
  }
}

@media (min-width: 840px) {
  .header_main .navigations {
    flex-direction: column;
  }
}

@media (min-width: 1430px) {
  .header_main .navigations {
    position: static;
    width: 100%;
    margin-top: 22.5px;
    margin-top: 1.25rem;
  }

  .c_header .c_form {
    margin: 0px 54px 0px 0px;
    margin: 0rem 3rem 0rem 0rem;
  }

  .header_main .logo {
    width: 100%;
  }

  .c_header .header_main > .logo_container {
    width: 144px;
    width: 8rem;
    top: 13.5px;
    top: 0.75rem;
    left: 18px;
    left: 1rem;
  }

  .header_main .navigations_aside {
    position: absolute;
    right: 18px;
    right: 1rem;
    top: 27px;
    top: 1.5rem;
  }

  .breadcrumb {
    display: block;
    background-color: #fff;
    padding: 9px 0px;
    padding: 0.5rem 0rem;
  }

  .nav-collapse ol ol {
    padding: 9px 0px 0px 0px;
    padding: 0.5rem 0rem 0rem 0rem;
  }

  .header_main {
    align-items: flex-end;
  }

  .header_main > .logo_container {
    width: 300px;
    left: 0;
  }
}

@media (min-width: 1540px) {
  .c_header {
    height: 72px;
    height: 4rem;
  }

  .c_header .navigations_aside {
    position: absolute;
    right: 0;
    top: 6.3px;
    top: 0.35rem;
  }
}

.hide {
  display: none;
}

footer ol,
footer ul {
  list-style: none;
}

footer .sep {
  display: none;
}

footer .alpha a {
  color: #fff;
}

footer .alpha {
  background-color: #3a3a3a;
  color: #fff;
  padding: 36px 18px 0px 18px;
  padding: 2rem 1rem 0rem 1rem;
}

footer .alpha > .row > * {
  padding-bottom: 36px;
  padding-bottom: 2rem;
}

footer .alpha p {
  padding-top: 0;
}

footer .logo_container {
  text-align: center;
  display: block;
}

footer .logo_container img {
  width: 250px;
  height: 70px;
}

footer p a,
footer li,
footer p {
  line-height: 43.199999999999996px;
  line-height: 2.4rem;
}

footer .beta {
  padding: 36px 18px 18px 18px;
  padding: 2rem 1rem 1rem 1rem;
}

footer .beta a {
  color: inherit;
}

footer .cm_form {
  margin: 0;
}

footer .beta li {
  line-height: 43.199999999999996px;
  line-height: 2.4rem;
  text-align: center;
}

footer .opening {
  line-height: 43.199999999999996px;
  line-height: 2.4rem;
}

footer .beta li p {
  padding-top: 0;
}

@media (min-width: 600px) {
  footer .alpha {
    padding: 36px 54px 0px 54px;
    padding: 2rem 3rem 0rem 3rem;
  }

  footer .sep {
    display: inline-block;
  }

  footer .alpha > .row > * {
    width: 31%;
  }

  footer .beta li:last-child {
    display: none;
  }
}

@media (min-width: 1430px) {
  .nav-social {
    background-color: #f0f0f1;
    padding: 18px 0px;
    padding: 1rem 0rem;
  }

  .nav-social ol {
    list-style-type: none;
  }

  .nav-social a {
    color: #3c424f;
  }

  .nav-social li {
    padding: 0px 18px;
    padding: 0rem 1rem;
    height: 36px;
    height: 2rem;
  }

  .nav-social li img {
    vertical-align: text-bottom;
    width: 36px;
    width: 2rem;
    height: 36px;
    height: 2rem;
  }

  .nav-social li p {
    padding: 0;
    margin: 0;
  }
}

.c_field input::placeholder,
.c_field textarea::placeholder {
  color: #fff;
}

.c_field.active input::placeholder,
.c_field.active textarea::placeholder {
  color: #757575;
}

.c_field .label {
  position: absolute;
  top: 11px;
  left: 8px;
  color: #757575;
}

.c_field.active .label {
  top: 0;
  left: 5px;
  font-size: 12px;
  color: #222;
}

.c_field.filled .label {
  top: 0;
  left: 5px;
  font-size: 12px;
  color: #222;
}

.c_field.c_form_array .label {
  left: 5px;
  right: 0;
  top: 0;
}

.c_field.c_form_array.active .label {
  position: absolute;
  top: -16px;
}

.c_field.c_form_array.filled .label {
  left: 5px;
  position: absolute;
  line-height: 1rem;
}

.cm_form .heading {
  text-align: center;
}

.contfrom_form {
  color: #3c424f;
  margin-top: 36px;
  margin-top: 2rem;
  background-color: #caa274;
}

.cm_form .c_field .label {
  left: 16px;
}

.cm_form .c_form_checkbox a {
  color: #3c424f;
  text-decoration: underline;
}

.cm_form .c_field.filled .label,
.cm_form .c_field.filled .label {
  left: 16px;
}

.cm_form .divide:after {
  border-bottom-color: #3c424f;
  margin: 0 auto;
}

.c_form .c_field {
  color: #3c424f;
  padding: 0px 9px 18px 9px;
  padding: 0rem 0.5rem 1rem 0.5rem;
}

.c_field .label {
  top: 9px;
  left: 18px;
}

.cm_form .row .button {
  background-color: #18ffff;
  padding: 18px 72px;
  padding: 1rem 4rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.cm_form > * {
  padding: 36px 0px;
  padding: 2rem 0rem;
}

footer .custom {
  margin: 1rem auto;
}

footer .custom > * {
  padding: 0 1rem;
}

footer .custom img {
  padding: 18px;
  padding: 1rem;
  width: 49%;
  height: 100%;
}

@media (min-width: 600px) {
  footer .custom img {
    width: inherit;
  }
}

@media (min-width: 1220px) {
  footer .custom > * {
    padding: 0;
  }
}

.c_form .success > * {
  text-align: center;
}

.c_form .c_virtual a {
  white-space: nowrap;
  height: 100%;
  width: 100%;
}

.c_arrows {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.c_arrows.right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.c_arrows.left {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.c_arrows.up {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.c_arrows.down {
  margin-right: 8px;
  -webkit-transform: rotate(45deg) translateY(-3px) translateY(-3px);
  transform: rotate(45deg) translateY(-3px) translateY(-3px);
}

.cm_1 .cm-1_pic {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  height: auto;
}

.cm_1 .box {
  width: 100%;
  position: relative;
}

.cm_1 .box .pos {
  position: absolute;
  top: 72px;
  top: 4rem;
  width: 100%;
}

@media (min-width: 840px) {
  .cm_1 .box .pos {
    top: 180px;
    top: 10rem;
  }

  .cm_1 .box .pos > * {
    margin: 0 auto;
  }

  .cm_1 .heading {
    display: block;
    position: absolute;
    bottom: 20%;
    background-color: #caa274;
    padding: 18px;
    padding: 1rem;
    width: 40%;
    text-align: right;
  }
}

.cm_3 {
  padding-top: 36px;
  padding-top: 2rem;
}

.cm_3 .divide {
  margin-bottom: 0;
}

.cm_3 > .content,
.cm_3 > .content > * {
  letter-spacing: 0.6300000000000001px;
  letter-spacing: 0.035rem;
}

.cm_3 .sprites {
  width: 55px;
  height: 55px;
}

.cm_3 .grow {
  padding-top: 54px;
  padding-top: 3rem;
}

.cm_3 .box {
  padding: 18px;
  padding: 1rem;
  border-bottom-color: #f0f0f1;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: center;
}

.cm_3 .box:last-child {
  border-bottom-width: 0px;
}

@media (min-width: 840px) {
  .cm_3 .box {
    border-bottom-width: 0;
    border-right-color: #f0f0f1;
    border-right-width: 1px;
    border-right-style: solid;
  }

  .cm_3 .box:last-child {
    border-right-width: 0px;
  }
}

.cm_4 {
  padding-top: 54px;
  padding-top: 3rem;
}

.cm_4 .box {
  padding: 0px 9px;
  padding: 0rem 0.5rem;
}

.cm_4 .cm-4_pic {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  height: auto;
}

.cm_4 .box .header {
  text-transform: uppercase;
  text-align: center;
}

.row {
  display: flex;
  flex-direction: column;
}

.row.keep {
  display: flex;
  flex-direction: row;
}

.row.keep.spaced {
  justify-content: space-between;
}

.row.spaced.wrap {
  flex-wrap: initial;
}

@media (min-width: 840px) {
  .row {
    display: flex;
    flex-direction: row;
  }

  .row.start {
    justify-content: flex-start;
  }

  .row.end {
    justify-content: flex-end;
  }

  .row.spaced {
    justify-content: space-between;
  }

  .row.spaced.around {
    justify-content: space-around;
  }

  .row.spaced.wrap {
    flex-wrap: wrap;
  }
}

.row.grow > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.row.wrap {
  flex-wrap: wrap;
}

.c_4 {
  color: #3c424f;
}

.c_4 .location {
  text-transform: uppercase;
  font-weight: 300;
}

.c_4 .tags {
  padding-bottom: 18px;
  padding-bottom: 1rem;
}

.c_4 .details {
  padding: 36px 0px;
  padding: 2rem 0rem;
  flex-wrap: wrap;
}

.c_4 .details > * {
  flex-direction: column;
  width: 50%;
  text-align: center;
}

.c_4 .details > div > * {
  padding-bottom: 9px;
  padding-bottom: 0.5rem;
}

.c_4 .sprites {
  width: 55px;
  height: 55px;
}

.c_4 .price {
  font-family: "Cormorant Infant", sans-serif;
  font-size: 34px;
  font-size: 2rem;
}

.c_4 .actions > * {
  margin-bottom: 18px;
  margin-bottom: 1rem;
}

.c_4 picture img.hero.c-4_pic {
  min-height: auto;
}

.c-5 {
  background-color: #eac25c;
  padding: 13.5px 27px;
  padding: 0.75rem 1.5rem;
  border-radius: 9px;
  border-radius: 0.5rem;
  display: inline-block;
}

@media (min-width: 1024px) {
  .c_4 {
    display: flex;
    flex-direction: row;
  }

  .c_4 .actions > * {
    margin-right: 18px;
    margin-right: 1rem;
  }

  .c_4 .slides {
    width: 60%;
  }

  .c_4 .box {
    padding-left: 36px;
    padding-left: 2rem;
    width: 40%;
  }

  .c_4 .details > * {
    width: auto;
  }
}

.cm_5 {
  padding: 54px 0px;
  padding: 3rem 0rem;
}

.cm_5 .cm-4_pic {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  height: auto;
}

.cm_5 .body {
  padding-bottom: 36px;
  padding-bottom: 2rem;
}

.cm_5 .box .header {
  text-transform: uppercase;
  text-align: center;
}

@media (min-width: 1024px) {
  .cm_5 .box {
    padding: 0px 0px 0px 36px;
    padding: 0rem 0rem 0rem 2rem;
  }
}

.c_slider.slider-inactive > .slides > *:not(:first-child) {
  display: none;
}

.c_slider.slider-active {
  position: relative;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.c_slider.slider-active .slides,
.c_slider.slider-inactive .slides {
  position: relative;
  overflow: hidden;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  display: flex;
  flex-direction: row;
}

.c_slider.slider-active .slides > * {
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.c_slider.slider-active .slides a:active,
.c_slider.slider-active .slides a:active img {
  outline: none;
}

.c_slider.slider-active,
.c_slider.slider-active .slider-dots,
.c_slider.slider-active .slides,
.c_slider.slider-active .slides > * {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.c_slider.slider-mouse .slides {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: move;
}

.c_slider.slider-mouse.slider-drag .slides * {
  cursor: move;
}

.slider-right,
.slider-left {
  position: absolute;
}

.slider-left {
  left: 0;
}

.slider-right {
  right: 0;
}

.slider-dots {
  margin: 0;
  padding: 0;
  text-align: center;
  list-style-type: none;
}

.c_slider.slider-active .slider-dots {
  margin: 0;
}

.c_slider.slider-active .slides + .slider-dots {
  margin: 0;
}

.slider-dots > li {
  display: inline-block;
  position: relative;
  padding: 1rem;
  width: 12px;
  height: 12px;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* *display:inline;
	*zoom:1; */
}

.slider-dots > li.slider-mouse-clicked,
.slider-dots > li:active {
  outline: 0;
}

.slider-dots > li > span {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -4px 0 0 -4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ccc;
}

.slider-dots > li.slider-active-dot > span {
  margin: -6px 0 0 -6px;
  width: 10px;
  height: 10px;
  background: white;
}

.c_11 .c_icon {
  width: 27px;
  width: 1.5rem;
  height: 27px;
  height: 1.5rem;
  margin-right: 9px;
  margin-right: 0.5rem;
}

.c_1_1 {
  background-color: #65666a;
  color: #fff;
  padding: 10.799999999999999px 18px;
  padding: 0.6rem 1rem;
  font-weight: 300;
  text-transform: uppercase;
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  border-color: #65666a;
  text-align: center;
  courser: pointer;
}

.c_1_1.light {
  background-color: #fff;
  color: #65666a;
  border-width: 2px;
  border-style: solid;
  border-color: #65666a;
}

.c_1_1:hover {
  background-color: #fff;
  color: #caa274;
  border-width: 2px;
  border-style: solid;
  border-color: #caa274;
}

.c_5 {
  color: #3c424f;
  padding: 18px 18px 0px 18px;
  padding: 1rem 1rem 0rem 1rem;
  margin-bottom: 18px;
  margin-bottom: 1rem;
  background-color: #fff;
  position: relative;
}

.c_5 .clicks a {
  margin-bottom: 18px;
  margin-bottom: 1rem;
}

.c_5 .link {
  display: inline-block;
}

.c_5 .message {
  -webkit-animation: fadeinout 3s ease-out forwards;
  animation: fadeinout 3s ease-out forwards;
  opacity: 0;
}

.c_5 .favicon {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 99;
  display: block;
}

.c_5 .favicon img {
  width: 20px;
  height: 20px;
}

.c_5 .location {
  text-transform: uppercase;
  font-weight: 300;
}

.c_5 .c_slider {
  margin: -18px -18px 0px -18px;
  margin: -1rem -1rem 0rem -1rem;
  height: 223px;
}

.c_5 .heading {
  height: 90px;
  height: 5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.c_5 .tags {
  padding-bottom: 18px;
  padding-bottom: 1rem;
}

.c_5 .details {
  padding: 36px 0px;
  padding: 2rem 0rem;
}

.c_5 .details > * {
  flex-direction: column;
  text-align: center;
}

.c_5 .details > div > * {
  padding-bottom: 9px;
  padding-bottom: 0.5rem;
}

.c_5 .sprites {
  width: 50px;
  height: 50px;
}

.c_5 .price {
  font-family: "Cormorant Infant", sans-serif;
  font-size: 34px;
  font-size: 2rem;
}

.c_5 .short {
  font-size: 13.5px;
  font-size: 0.75rem;
  border-bottom: 1px solid #0000001c;
  padding-bottom: 4.5px;
  padding-bottom: 0.25rem;
}

@media (min-width: 840px) {
  .c_5 {
    width: 32.5%;
  }
}

.cm_15 {
  background-color: #f0f0f1;
  padding: 54px 0px;
  padding: 3rem 0rem;
}

.cm_15 .box {
  padding: 0px 9px;
  padding: 0rem 0.5rem;
}

.cm_15 .cm-4_pic {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  height: auto;
}

.cm_15 .body {
  padding-bottom: 36px;
  padding-bottom: 2rem;
}

.cm_15 .box .header {
  text-transform: uppercase;
  text-align: center;
}

.c_1 {
  background-color: #65666a;
  color: #fff;
  padding: 22.5px 36px 22.5px 36px;
  padding: 1.25rem 2rem 1.25rem 2rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4.5px;
  letter-spacing: 0.25rem;
  display: inline-block;
  cursor: pointer;
}

.cm_6 .box {
  background-color: #3a3a3a;
  color: #fff;
  padding: 45px 54px;
  padding: 2.5rem 3rem;
}

.cm_6 .heading {
  padding-bottom: 36px;
  padding-bottom: 2rem;
}

.cm_6 .body {
  padding-bottom: 36px;
  padding-bottom: 2rem;
}

.cm_6 .cm-6_pic {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  height: 100%;
}

@media (min-width: 1280px) {
  body .cm_6 img.hero.cm-6_pic {
    height: 100%;
  }
}

@media (min-width: 840px) {
  .cm_6 .pic {
    width: 60%;
    line-height: 0;
  }

  .cm_6 .box {
    width: 40%;
  }
}

/*  */
.row.group.center_vh {
  background-color: #00000047;
  padding: 15px;
}

.c_virtual.c_field {
  position: relative;
}

.c_virtual a {
  white-space: nowrap;
  height: 100%;
  width: 100%;
  color: #fff;
}

.row.keep {
  display: flex;
  flex-direction: row;
}

.c_virtual {
  border: 1px solid white;
  white-space: nowrap;
  position: relative;
  width: 100%;
  height: 45px;
  height: 2.5rem;
  margin: 0px 0px 18px 0px;
  margin: 0rem 0rem 1rem 0rem;
}

main .action_button .button {
  border: 1px solid white;
  white-space: nowrap;
  padding: 9.9px 36px;
  padding: 0.55rem 2rem;
  background-color: #00000047;
  display: block;
  text-align: center;
  color: #fff;
  font-weight: 600;
}

.material-textfield {
  position: relative;
  flex: 0 105%;
  white-space: nowrap;
  margin: 0px 10px 0 0;
  font-size: 16px;
}

.c_virtual.c_field {
  position: relative;
  margin: 0px 10px 0 0;
}

.c_virtual a {
  font-size: 16px;
}

.auto.c_3 {
  max-width: 1140px;
}

a.row.keep.center_vh span {
  color: #fff;
}

a.row.keep.center_vh i {
  color: #fff;
}

i.down.c_arrows {
  position: absolute;
  right: 9px;
  top: 20px;
  font-size: 22px;
  color: #fff;
  border-color: #fff;
}

.row.group.center_vh .action_button {
  padding-bottom: 0px !important;
}

.c_form_search_filters_selected .label {
  margin: 0px 9px;
  margin: 0rem 0.5rem;
}

.c_form_search_filters_selected .tag {
  font-weight: bold;
}

.c_form_search_filters .results {
  margin-left: 18px;
  margin-left: 1rem;
}

.c_icon {
  display: inline-block;
  line-height: 24px;
  height: 20px;
  width: 20px;
  direction: ltr;
  margin-right: 10px;
}

.c_form_search .box {
  padding-right: 18px;
  padding-right: 1rem;
  position: relative;
}

.c_form_search .c_form_string {
  min-width: 324px;
  min-width: 18rem;
}

.c_form_search .c_icon {
  font-size: 36px;
  font-size: 2rem;
  line-height: 36px;
  line-height: 2rem;
}

.c_form_search .c_icon.before {
  position: absolute;
  top: 18px;
  top: 1rem;
  z-index: 1;
}

.c_form_search .icon_after {
  position: absolute;
  right: 0;
  top: 16.2px;
  top: 0.9rem;
  width: 36px;
  width: 2rem;
  z-index: 1;
}

.c_form_search .icon_after .c_icon {
  margin-top: 0;
}

.c_form_search .c_field {
  padding: 0;
}

.c_form_search .c_field .label {
  left: 36px;
  left: 2rem;
  top: 16.2px;
  top: 0.9rem;
}

.c_form_search .c_field.active .label {
  left: 0;
  top: 0;
}

.c_form_search .c_field.filled .label {
  left: 0;
  top: 0;
}

.c_form_search .c_field input {
  padding-left: 31.5px;
  padding-left: 1.75rem;
}

.c_form_range_inputs {
  width: 100%;
  position: relative;
}

.c_form_range_inputs button {
  white-space: nowrap;
}

.c_form_range_inputs .box {
  padding-bottom: 9px;
  padding-bottom: 0.5rem;
}

@media (min-width: 840px) {
  .c_form_range_inputs .before {
    padding-right: 9px;
    padding-right: 0.5rem;
  }

  .c_form_range_inputs .before.to {
    padding-left: 18px;
    padding-left: 1rem;
  }

  .c_form_range_inputs .box {
    padding-bottom: 0px;
    padding-bottom: 0rem;
  }
}

.c_form_int {
  width: 100%;
  position: relative;
}

.c_form_string {
  width: 100%;
  position: relative;
}

input[type="text"],
input[type="number"],
input[type="password"] {
  height: 45px;
  height: 2.5rem;
  font-size: 18px;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 1px;
  width: 100%;
  box-shadow: none;
  border: 1px solid #ccc;
  padding: 9px 9px 9px 9px;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  display: inline-block;
}

.c_form_array {
  width: 100%;
  white-space: nowrap;
  background-color: #fff;
  position: relative;
}

.c_form_array.active .label {
  position: absolute;
  top: 0;
}

.c_form_array .label {
  position: relative;
  display: flex;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0px 9px;
  padding: 0rem 0.5rem;
  line-height: 45px;
  line-height: 2.5rem;
}

.c_form_array.active .dropdown,
.c_form_array.filled .dropdown {
  display: block;
}

.c_form_array .dropdown {
  background-color: #fff;
  display: none;
}

.c_form_array .open {
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 10;
  padding: 0px 0px 0px 9px;
  padding: 0rem 0rem 0rem 0.5rem;
  border: 1px solid #ccc;
}

.c_form_array li {
  color: #333;
  cursor: pointer;
}

.c_form_array li:hover {
  background-color: #ccc;
}

.c_form_array .c_arrows {
  position: relative;
  right: 0;
}

.c_form_array .placeholder {
  color: #676767;
  text-align: left;
  height: 45px;
  height: 2.5rem;
  line-height: 45px;
  line-height: 2.5rem;
  padding-left: 9px;
  padding-left: 0.5rem;
}

.c_form_array .placeholder:hover {
  cursor: pointer;
}

.hide {
  display: none;
}

.c_arrows {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.c_arrows.right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.c_arrows.left {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.c_arrows.up {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.c_arrows.down {
  margin-right: 8px;
  -webkit-transform: rotate(45deg) translateY(-3px) translateY(-3px);
  transform: rotate(45deg) translateY(-3px) translateY(-3px);
}

.c_form_checkbox_list {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  border-width: 0;
}

.c_form_checkbox_list label {
  white-space: nowrap;
}

.c_form_checkbox_list legend {
  font-size: 12px;
  color: #999;
  padding-bottom: 4.5px;
  padding-bottom: 0.25rem;
}

.c_form_checkbox_list .list {
  padding-left: 18px;
  padding-left: 1rem;
}

.row {
  display: flex;
  flex-direction: column;
}

.row.keep {
  display: flex;
  flex-direction: row;
}

.row.keep.spaced {
  justify-content: space-between;
}

.row.spaced.wrap {
  flex-wrap: initial;
}

@media (min-width: 840px) {
  .row {
    display: flex;
    flex-direction: row;
  }

  .row.start {
    justify-content: flex-start;
  }

  .row.end {
    justify-content: flex-end;
  }

  .row.spaced {
    justify-content: space-between;
  }

  .row.spaced.around {
    justify-content: space-around;
  }

  .row.spaced.wrap {
    flex-wrap: wrap;
  }
}

.row.grow > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.row.wrap {
  flex-wrap: wrap;
}

.c_form_checkbox_list_tree {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  border-width: 0;
}

.c_form_checkbox_list_tree legend {
  font-size: 12px;
  color: #999;
  padding-bottom: 4.5px;
  padding-bottom: 0.25rem;
}

.c_form_checkbox_list_tree .has_children {
  padding-bottom: 9px;
  padding-bottom: 0.5rem;
}

.c_form_checkbox_list_tree .has_children > .c_form_checkbox label {
  font-weight: 600;
}

.c_form_checkbox_list_tree .c_form_checkbox.c_field {
  padding-bottom: 9px;
  padding-bottom: 0.5rem;
}

.center {
  display: flex;
  justify-content: center;
}

.center_v {
  display: flex;
  align-items: center;
}

.center_vh {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c_form_checkbox {
  position: relative;
}

.c_form_checkbox.c_field label {
  position: initial;
}

.c_form_checkbox input {
  display: inline-box;
  transform: scale(1.5);
  margin-right: 18px;
  margin-right: 1rem;
}

/* ------------------------------------   ------------------------------- */

.cm_2 {
  padding: 36px 0px;
  padding: 2rem 0rem;
}

ol.row.keep.center_vh li {
  margin: 0px 18px;
}

body .cm_6 img.hero.cm-6_pic {
  height: 100%;
}

img.hero.cm-6_pic {
  min-height: 340px;
}

.pic img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.cm_3 .sprites {
  height: auto !important;
}

.sliderimage img {
  width: 100%;
}

.c_slider .slides {
  width: 100%;
}

.row.spaced.sliderimage a {
  width: 100%;
}

.adj_image_width img {
  min-height: 250px;
}

.banner_pic {
  height: 520px;
  width: 100%;
  object-fit: cover;
}

/* input  */

.material-textfield {
  position: relative;
}

.material-textfield label {
  position: absolute;
  font-size: 16px;

  left: 0;
  top: 25%;
  transform: translateY(-25%);
  background-color: white;
  color: gray;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.material-textfield input {
  font-size: 1rem;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 1rem 0.7rem;
  color: gray;
  transition: 0.1s ease-out;
}

.material-textfield input:focus {
  border-color: gray;
}

.material-textfield input:focus + label {
  color: gray;
  top: 0;
  transform: translateY(-10%) scale(0.9) !important;
}

.material-textfield input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-25%) scale(0.9) !important;
}

.material-textfield input:empty + label {
  transform: translateY(0%);
}

.material-textfield input:empty:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9) !important;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-focused.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  top: 2px !important;
}

.material-textfield input:empty::-webkit-input-placeholder {
  opacity: 0;
}

/*  */
.activevalue .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(13px, 2px) scale(0.75) !important;
}

.emptyvalue input:hover + label,
.activevalue input + label,
.emptyvalue textarea:hover + label,
.activevalue textarea + label {
  color: gray;
  top: 0;
  transform: translateY(5%) scale(0.9) !important;
  background: transparent;
  font-size: 13px;
}
.activevalue input:focus + label,
.emptyvalue input:focus + label {
  color: gray;
  top: 0;
  transform: translateY(5%) scale(0.9) !important;
}
/* .emptyvalue input::placeholder {
	color: #fff !important;
	opacity: 0;
  }  */
.emptyvalue input::placeholder,
.emptyvalue textarea::placeholder {
  color: gray !important;
  opacity: 0px !important;
}
.emptyvalue:hover input::placeholder,
.emptyvalue:hover textarea::placeholder {
  color: gray !important;
  opacity: 1 !important;
}
.activevalue input::placeholder,
.activevalue textarea::placeholder {
  color: gray !important;
  opacity: 1 !important;
}

.material-textfield.modfinfo2 label {
  top: 22px;
}
.material-textfield.modfinfo2.activevalue label {
  top: 0;
}
.material-textfield.activevalue label {
  transform: translateY(0) scale(0.9) !important;
  top: 9px !important;
}
.header_top .material-textfield input:empty:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-35%) scale(0.9) !important;
}
.material-textfield.modfinfo2.activevalue label {
  top: 3px !important;
}

.material-textfield2.emptyvalue
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-focused.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  top: 10px !important;
}
.material-textfield2.activevalue
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-focused.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  /* top: 10px !important; */
}
/*  */

/*  */
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-nav li.nav-item {
  position: relative;
  margin: 5px;
  font-size: 13px;
}

ul.topMenu li.nav-item {
  margin: 10px 2px;
}
ul.topMenu li.nav-item a.nav-link {
  font-size: 13px;
}
ul.topMenu li.nav-item a.nav-link.headerSize {
  font-size: 15px;
}
ul.topMenu li.nav-item:nth-last-child(1) a ~ ul.nav.drop-menu {
  left: auto;
  right: 0;
  text-align: right;
}
.navbar-nav li.nav-item:hover ul.nav.drop-menu {
  display: block;
}

.navbar-nav ul.nav.drop-menu {
  position: absolute;
  background: #fff;
  padding: 2px 10px;
  font-size: 16px;
  display: none;
  width: 200px;
}

.navbar-nav ul.nav.drop-menu li {
  font-size: 16px;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none !important;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    display: flex;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    flex: 1;
  }
}

/* .box {
    flex: 0 50%;
}
.slides_area {
    max-width: 60%;
    position: relative;
} */

.slides_area {
  width: 60%;
}

.slick-next,
.slick-prev {
  /* background: gray; */
  z-index: 100;
}

.slick-prev {
  left: 12px;
  z-index: 10000;
}

.slick-next {
  right: 12px;
}

.virtual.group {
  box-shadow: 0px 10px 10px -7px rgb(0 0 0 / 50%);
  background: #fff;
  padding: 18px 18px 18px 18px;
  padding: 1rem 1rem 1rem 1rem;
}

.inp_row_cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.def_btn_opt button {
  padding: 10.799999999999999px 18px;
  padding: 0.6rem 1rem;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  background-color: #eac25c;
  color: #65666a;
  border-width: 2px;
  border-style: solid;
  border-color: #65666a;
  cursor: pointer;
}

.c_form_loading {
  position: relative;
  z-index: 100;
}

.from_optview1,
.from_optview2,
.from_optview3 {
  /* display: none; */
}

.c_form_search_filters_selected .remove .tag {
  min-width: 175px;
  background-color: #caa274;
}

.c_form_search_filters_selected .tag {
  background: #00000047;
  border-radius: 5px;
  margin: 15px 0 0 5px;
  padding: 2px 9px;
  font-size: 16px;
}

main .c_form .c_form_search_filters_selected .tag {
  background: #00000047;
  border-radius: 5px;
  margin: 15px 0 0 5px;
  padding: 2px 9px;
  font-size: 16px;
}

.c_form_search_filters_selected {
  display: none;
}

img.img-fluid.brand-logo {
  width: 150px;
}

section.header-wrapper {
  padding: 10px;
  position: relative;
  z-index: 1000;
}

.header_top {
  display: flex;
  justify-content: flex-end;
}

.header_top p span.count {
  border: 2px solid;
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-weight: 600;
}

.ref_search_opt {
  display: flex;
  margin-right: 11px;
  position: relative;
}

.select_language-cont div#demo-simple-select {
  padding: 11px 40px 12px 9px;
  line-height: normal !important;
  font-size: 16px;
}

.ref_search_opt button.btn.btn-default {
  background: 0px;
  border: 0px;
  position: absolute;
  right: 10px;
  background: transparent;
  height: 45px;
}

a.nav-link img {
  margin-left: 6px;
}

.demo-simple-select-label {
  top: 15px;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  max-width: calc(100% - 12px);
  top: -7px;
}

label#demo-simple-select-label {
  font-size: 16px;
  top: 2px;
  /* font-family: Montserrat, sans-serif;
		max-width: 130px; */
}

.select_language-cont div#demo-simple-select {
  line-height: normal !important;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
}

.ref_search_opt button.btn.btn-default {
  cursor: pointer;
}

.button:hover {
  background-color: #fff;
  color: #caa274;
  border-width: 2px;
  border-style: solid;
  border-color: #caa274;
}

.googlemap iframe {
  width: 100%;
  height: 300px;
}

.googlemap2 iframe {
  width: 100%;
  height: 350px;
}

.contactfrom .material-textfield {
  margin-bottom: 15px;
}

.contactfrom .material-textfield input {
  height: 50px;
}

.inprow_opr2 {
  position: relative;
  display: flex;
  margin: 0px;
}

.contactfrom .material-textfield {
  margin-bottom: 15px;
}

.material-textfield input {
  width: 100%;
}

.inprow_opr1 textarea,
.inprow_opr1 select {
  background-color: #fff;
  width: 100%;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 1rem 0.7rem;
  color: gray;
}

div#demo-simple-select {
  background: #fff;
  border: 1px solid gray;
}

.contactfrom .material-textfield ::placeholder {
  color: gray;
  font-size: 16px;

  opacity: 1;
  /* Firefox */
}

.contactfrom .material-textfield ::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: gray;
  font-size: 16px;
}

.material-textfield2 input:focus + label {
  color: gray;
  top: 0;
  transform: translateY(-10%) scale(0.9) !important;
}

.material-textfield2 {
  padding: 0px 10px 15px 0;
}

.cm_form .heading {
  text-align: center;
  margin-bottom: 20px;
}

.nav-social {
  background-color: #f0f0f1;
  padding: 18px 0px;
  padding: 1rem 0rem;
}

.nav-social li {
  padding: 0rem 0rem;
  height: 36px;
  height: 2rem;
}

.nav-social li p {
  padding: 0;
  margin: 0;
}

.nav-social li img {
  vertical-align: text-bottom;
  width: 36px;
  width: 2rem;
  height: 36px;
  height: 2rem;
}

.inner_cont_wrap {
  background: #f0f0f1;
}

.pagination_opt {
  text-align: center;
  justify-content: center;
  padding-bottom: 50px;
}

.css-wjh20t-MuiPagination-ul {
  justify-content: center;
}

.inner_cont_wrap .c_form_loading {
  padding-top: 33px;
}

.row.keep.spaced.infos.auto {
  padding: 15px 0 0;
}

.defbasic_button button {
  /* color: #000 !important; */
  cursor: pointer;
  color: #3c424f;
  padding: 0px 17px 0 0;
  background: url(../src/assets/img/down-arrow-thick.png) no-repeat right 2px;
}

.row.keep.spaced.infos.auto {
  padding: 15px 0 0;
  background: #fff;
  padding: 15px;
  margin-top: 30px;
}

.contactinfo p {
  margin: 0;
}

section.immobilieVerkaufen {
  margin-bottom: 58px;
}

.nopadding {
  padding: 0px !important;
  margin: 0px !important;
}

.c_12 .indicator {
  /* padding: 5.3999999999999995px; */
  padding: 2px 10px 3px;
  font-weight: bold;
  color: #fff;
  position: relative;
  display: block;
}

.bgopt1 {
  background-color: #333;
  width: 10%;
}

.bgopt2 {
  background-color: #444;
  width: 20%;
}

.bgopt3 {
  background-color: #555;
  width: 30%;
}

.bgopt4 {
  background-color: #666;
  width: 40%;
}

.bgopt5 {
  background-color: #777;
  width: 50%;
}

.bgopt6 {
  background-color: #888;
  width: 60%;
}

.bgopt7 {
  background-color: #999;
  width: 70%;
}

.c_12.inactive .i_0:after {
  border-left-color: #333;
}

.c_12.inactive .i_1:after {
  border-left-color: #444;
}

.c_12.inactive .i_2:after {
  border-left-color: #555;
}

.c_12.inactive .i_3:after {
  border-left-color: #666;
}

.c_12.inactive .i_4:after {
  border-left-color: #777;
}

.c_12.inactive .i_5:after {
  border-left-color: #888;
}

.c_12.inactive .i_6:after {
  border-left-color: #999;
}

.c_12 .i_6:after {
  border-left-color: #fd0100;
}

.c_12 .indicator:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  border: 0.91rem solid transparent;
  border-left-width: 0.9rem;
  border-left-style: solid;
}

.c_12.inactive .row.spaced {
  margin-bottom: 8px;
}

.c_9 .label {
  font-weight: 600;
  padding-bottom: 10px;
  display: block;
}

.further_Information_block {
  padding: 60px 0px;
}

.c_7 .contact > * {
  width: 50%;
}

.c_7 .phone {
  padding-bottom: 18px;
  padding-bottom: 1rem;
}

.inp_row_block_wrap {
  display: flex;
  justify-content: space-between;
}

.inp_row_block_wrap .inp_row_block {
  flex: 0 49%;
}

.inp_row_block input {
  line-height: normal !important;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
}

.MuiFormLabel-root {
  background-color: white !important;
  color: gray !important;
  padding: 0 0.3rem !important;
}

.c_form_checkbox.c_field {
  /* margin-bottom: 20px; */
}

textarea#outlined-textarea {
  font-size: 16px;
  font-family: Montserrat, sans-serif;
}

.inp_row_block
  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
  margin-bottom: 23px;
  background: #fff;
  border: 1px solid #ccc !important;
  outline: 0px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}

.inp_row_block input {
  padding: 5px 20px;

  border: 0px;
}

.c_8 {
  color: #3c424f;
  padding: 72px 0px;
  padding: 4rem 0rem;
}

.c_8 .label {
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 18px;
  padding-bottom: 1rem;
}

.c_8 .body {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #3c424f;
  padding-bottom: 18px;
  padding-bottom: 1rem;
}

.txt_2,
h2 {
  font-size: 27px;
  padding-top: 4px;
  margin-bottom: 24px;
  line-height: 28px;
}

.poperty_des_moreinfo {
  background: #fff;
}

.poperty_des {
  padding: 60px 0px;
}

.poperty_des .heading {
  text-align: left;
}

.c_6 .estate_slider {
  width: 60%;
  padding-right: 36px;
  padding-right: 2rem;
}

.c_6 .details {
  width: 40%;
}

.c_6 .location {
  text-transform: uppercase;
  font-weight: 300;
}

.c_6 .data.row.wrap {
  flex-wrap: initial;
}

.c_6 .data > * {
  flex-direction: column;
  text-align: center;
}

.center_v {
  display: flex;
  align-items: center;
}

.c_6 .data > div > * {
  padding-bottom: 9px;
  padding-bottom: 0.5rem;
}

.c_6 .sprites {
  width: 55px;
  height: 55px;
}

.c_6 .data .price_label {
  flex-basis: auto;
  padding-top: 6px;
  padding-bottom: 30px;
}

.c_6 .details2 {
  padding: 36px 0px;
  padding: 2rem 0rem;
}

.c_6 .price {
  font-family: "Cormorant Infant", sans-serif;
  font-size: 34px;
  font-size: 2rem;
}

.c_6 .actions .c_1_1 {
  margin-right: 18px;
  margin-right: 1rem;
}

.addprod_view {
  background: #fff !important;
  padding: 20px 0px 40px;
}

.estate_slider .row.keep.spaced {
  flex-direction: column;
}

.clickBox {
  width: 100%;
  flex: 1;
  display: block;
  width: 100%;
  margin: 0px 0px 15px;
}

.clickBox img {
  width: 100%;
}

.thumb-wrapper {
  display: flex;
  flex: auto;
  flex-wrap: wrap;
}

.thumb-item {
  flex: 0 10%;
  margin: 1%;
}

.thumb-wrapper {
  display: flex;
  flex: auto;
  overflow-x: overlay;
}

.select_language-cont .MuiFormControl-root {
  width: 95px;
}

.auto.row.wrap.spaced.cols_2 .box {
  flex: 0 48%;
}

.auto.row.wrap.spaced.cols_2 .box img {
  width: 100%;
}

.cm_16 {
  padding: 36px 0px;
  padding: 2rem 0rem;
}

.box p strong {
  display: block;
}

.box .txt_2,
.box h2 {
  margin-bottom: 10px;
}

.box .heading.txt_2 {
  text-align: center;
}

.cm_16 .box a {
  display: inline-block !important;
}

.cm_17 .ratio {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.cm_17 iframe {
  height: 100%;
  width: 100%;
  position: absolute;
}

iframe {
  border: 0;
  max-width: 100%;
}

.innercontent ul {
  margin-bottom: 15px;
}

.partner_info {
  padding-bottom: 60px;
}

.partner_info .box img {
  width: auto !important;
}

.innercontent {
  margin-bottom: 30px;
}

.cmscontent p strong {
  display: block;
}

.cmscontent p em {
  display: block;
}

p {
  word-break: break-word;
}

.innercontent {
  padding-right: 1rem;
  padding-left: 1rem;
}

label#demo-simple-select-label {
  font-family: Montserrat, sans-serif;
}

.material-textfield input {
  width: 100%;
  height: 48px;
}

.popupinfo iframe {
  height: 68vh;
}

iframe {
  border: 0;
  max-width: 100%;
  width: 100%;
}

a.favicon
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #fff;
}

.c_1_1.light svg {
  fill: #65666a;
  width: 20px;
  margin-top: -4px;
}

@media (min-width: 991px) {
  .navbar svg.bi.bi-list {
    height: 36px;
    width: 26px;
  }
}
@media (max-width: 839px) {
  ul.topMenu li.nav-item a.nav-link {
    /* font-size: 13px; */
    padding-left: 12px;
  }
}

@media (min-width: 840px) {
  .c_9 .box {
    width: 30%;
  }

  .c_7 > .row > * {
    width: 45%;
  }

  .c_8 .box {
    width: 30%;
    margin-right: 3%;
  }

  .cm_17 .cols_2 > * {
    width: 49%;
  }
}

@media (max-width: 839px) {
  .c_5 .c_slider {
    height: auto;
  }

  .c_virtual.c_field {
    position: relative;
    margin: 10px 0 0;
  }

  .material-textfield {
    margin-top: 10px;
  }

  .action_button {
    margin-top: 10px;
  }

  .box .heading.txt_2 {
    text-align: left;
  }

  .cm_15 .heading.txt_1 {
    padding-left: 15px;
    padding-right: 15px;
  }

  footer p a,
  footer li,
  footer p {
    line-height: 2rem;
  }

  .beta p {
    text-align: center;
  }

  .cm_6 .box {
    padding: 1.5rem;
  }

  .further_Information_block {
    padding: 1.5rem;
  }

  .c_map_mapbox.mapboxgl-map {
    margin-bottom: 15px;
  }

  .poperty_des_moreinfo .auto.c_8 {
    padding: 2rem 1.5rem;
  }

  .poperty_des_moreinfo .box {
    margin-top: 1rem;
  }

  .poperty_des_moreinfo .txt_2,
  .poperty_des_moreinfo h2 {
    margin-bottom: 14px;
  }

  .poperty_des {
    padding: 2rem 1.5rem;
  }

  .txt_1,
  .txt_1 > *,
  h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .c_7 .contact > * {
    width: 100%;
  }

  .c_6 .details {
    width: 100%;
  }

  .c_6 .estate_slider {
    width: 100%;
    padding-right: 0px;
  }

  .addprod_view {
    padding: 1rem 1.5rem;
  }

  .c_6 .price {
    font-size: 1.8rem;
  }

  .details .row.actions a {
    margin: 0px 0px 15px;
  }

  .details {
    margin-bottom: 20px;
  }

  .txt_2,
  h2 {
    font-size: 23px;
  }

  .material-textfield {
    position: relative;
    flex: 0 1 100%;
    margin: 10px 0px 0 0;
    width: 100%;
  }

  button.navbar-toggler {
    background: #f49d00;
    color: #fff;
    border: 0px;
    padding: 6px 7px 4px;
    border-radius: 5px;
  }

  .navbar-nav li.nav-item:hover ul.nav.drop-menu {
    position: relative;
    width: auto;
    padding: 2px 0;
  }

  a.nav-link {
    width: fit-content;
    display: block;
    position: relative;
  }

  a.nav-link img {
    top: 7px;
    margin-left: 6px;
    position: absolute;
    right: 11px;
  }

  html,
  body {
    overflow-x: hidden;
  }

  .collapse.navbar-collapse.show {
    transform: translate(0px, 0px);
  }

  /* .collapse.navbar-collapse.show:after {
		position: absolute;
		left: 0p;
		right: 0px;
		top: 0px;
		background: #000000c4;
		height: 100vh;
		content: "";
		width: 300%;
	} */
  .navbarcontent_des {
    background: #fff;
    height: 100vh;
    position: relative;
    z-index: 10;
    box-shadow: 0px 0px 50px #000;
  }

  /* .header_top .ref_search_opt {
			display: none;
		} */

  /* .header_top {
			position: absolute;
			right: 55px;
		} */

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 14px;
    margin: 0px !important;
    padding: 5px 10px !important;
  }

  li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 14px;
  }

  .css-ece9u5 {
    min-width: auto;
  }

  .select_language-cont .MuiFormControl-root {
    width: 100%;
  }

  .auto.cm_2 {
    padding-top: 0;
  }

  button.navbar-toggler.cross {
    margin: 10px;
  }

  button.navbar-toggler.cross svg {
    width: 27px;
    height: 26px;
  }

  .select_language-cont div#demo-simple-select {
    padding: 11px 35px 12px 9px;
  }

  .css-ece9u5 {
    min-width: 125px !important;
  }

  .navbar-nav ul.nav.drop-menu li {
    font-size: 14px;
    margin: 0;
  }
  .c_virtual.c_field {
    width: 100%;
    max-width: 100% !important;
  }
  .material-textfield {
    max-width: 100% !important;
  }
  .swiper-wrapper .link {
    width: 100%;
  }
  .swiper-wrapper span.lazy-load-image-background.blur.lazy-load-image-loaded {
    width: 100%;
  }
  .swiper-wrapper
    span.lazy-load-image-background.blur.lazy-load-image-loaded
    img {
    width: 100% !important;
    height: auto !important;
  }
  ul.MuiPagination-ul.css-wjh20t-MuiPagination-ul li {
    margin-bottom: 10px;
  }

  .modf_def_cont_block {
    display: block;
    flex-wrap: initial;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 14px;
    color: #000;
    font-weight: 600 !important;
  }
  .navbar-nav li.nav-item {
    margin: 0px 6px;
  }
  .c_5 .details {
    padding: 1rem 0rem;
  }
  .innercontent {
    padding-top: 30px;
  }
  .box {
    margin-bottom: 25px !important;
  }
  .videoinfo {
    overflow: hidden;
    /* height: 100%; */
    width: 100%;
  }
}

@media (max-width: 767px) {
  .wrapper-row-content {
    flex-direction: column;
    width: 100%;
  }
  .list-name-content {
    width: 100%;
    margin: 10px 0px !important;
  }
  .def_btn_opt.list-name-content {
    text-align: center;
  }
  .row.keep.spaced.infos.auto {
    font-size: 14px;
  }
  .wrapPrivacyPolicy {
    display: flex;
    align-items: center;
    gap: 1;
    padding-left: 5px;
  }
  .cm_form.contfrom_form {
    padding: 10px;
  }
  .row.keep.spaced.infos.auto {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .c_list_sort.defbasic_button {
    padding: 8px;
  }

  .c_6 .sprites {
    width: 40px;
    height: 40px;
  }

  .c_5 .sprites {
    width: 40px;
    height: 40px;
  }

  p,
  li {
    font-size: 14px;
    line-height: 24px;
  }

  .txt_1,
  .txt_1 > *,
  h1 {
    font-size: 25px;
    line-height: 36px;
  }

  .heading,
  .heading > * {
    font-size: 28px;
  }

  .c_5 .heading {
    height: auto;

    font-size: 23px;
  }

  .txt_3,
  h3,
  .txt_3 > p,
  .txt > h3 {
    font-size: 18px;
  }

  .cm_3 .grow {
    padding-top: 1rem;
  }

  .heading,
  .heading > * {
    font-size: 24px;
  }

  .row.keep.spaced.details2 {
    padding-top: 0;
  }

  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 14px !important;
  }

  .inp_row_block input {
    font-size: 15px;
  }

  textarea#outlined-textarea {
    font-size: 15px;
  }
  main .c_form_checkbox_list.c_field_group .list label {
    font-size: 14px;
  }
  /* #menu- {
			position: absolute;
		
			inset: 0px;
		} */
  /* #basic-menu */

  .material-textfield.modfinfo2 label {
    white-space: pre-wrap;
  }
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-focused.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 15px !important;
  }

  .c_6 .data .price_label {
    width: 100%;
  }

  .c_form_checkbox.c_field {
    font-size: 14px;
  }
}

@media (max-width: 479px) {
  .c_5 .heading {
    height: auto;

    font-size: 23px;
  }

  .heading,
  .heading > * {
    font-size: 23px;
  }

  .row.wrap.spaced.keep.data {
    flex-wrap: wrap;
  }

  .c_6 .data .price_label {
    padding-top: 11px;

    width: 100%;
  }
}

@media (max-width: 359px) {
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-focused.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 14px !important;
  }

  .css-ece9u5 {
    min-width: 81px !important;
  }
}

.propertySerarchSlide .slick-slide img {
  display: block;
  height: 220px;
  width: 100%;
  object-fit: cover;
}

.static-width {
  display: flex;
  width: 50px;
  /* Set static width for salutation, first name, and last name */
  margin-right: 15px;
}

/* .inp_row_block .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
    margin-bottom: 23px;
    background: #fff;
    border: 1px solid #CCC !important;
    outline: 0px !important;
} */
.MuiFormControl-root.MuiTextField-root.css-i44wyl {
  width: 100%;
  /* background: #ffff; */
  margin-bottom: 20px;
  outline: 0px;
}

.css-1v4ccyo {
  height: 56px;
}

.css-v7esy {
  font-size: 12px;
}

.css-1ald77x.Mui-focused {
  background: transparent !important;
}

.Mui-focused {
  background: transparent !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-formControl.css-1v4ccyo {
  background: #b59c9c;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1v4ccyo {
  background: #fff;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline.css-1hof3tc {
  background: #fff;
}

.svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv path {
  color: #fff;
}

.css-v7esy.Mui-error {
  color: rgb(211, 47, 47);
  font-size: 11px !important;
}

.css-1ald77x {
  background: transparent !important;
}

/* .css-v7esy.Mui-error {
    color: rgb(211, 47, 47);
    font-size: 11px !important;
} */

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-formControl.css-1v4ccyo {
  background: none !important;
}

.css-1v4ccyo.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgb(211, 47, 47);
  background: #fff;
}

.swiper-pagination {
  position: relative !important;
  padding-top: 20px;
}

.swiper-scrollbar {
  margin-top: 10px;
}

/* .swiper-button-next:after  {
  
    font-family: 'slick' !important;
    font-size: 20px !important;
    line-height: 1 !important;
    opacity: .75  !important;
    color: white !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    content: '→'  !important;
	background-color: #54595F !important;
}

.swiper-button-prev:after {
  
    font-family: 'slick' !important;
    font-size: 20px !important;
    line-height: 1 !important;
    opacity: .75  !important;
    color: white !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    content: '←' !important;
	background-color: #54595F !important;
} */

.new-cat select {
  height: 45px;
  background: #adadae;
  color: #fff;
  border: 1px solid;
  margin-right: 10px;
  min-width: 105px;
  font-size: 16px;
  text-decoration: none;
  outline: none;
  padding: 10px;
}

@media (max-width: 767px) {
  .new-cat {
    width: 100%;
    margin-top: 10px;
  }

  .new-cat select {
    width: 100%;
    margin-right: 0px;
  }

  .thumb-wrapper {
    flex-wrap: nowrap;
  }

  .thumb-wrapper .thumb-item {
    min-width: 40px;
  }
}

.main-image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-image {
  width: 100%;
  height: auto;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #54595f;
  color: white;
  border: none;
  padding-top: 5px;
  cursor: pointer;
  z-index: 1;
}

.prev-button {
  /* left: 10px; */
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 24px;
}

.next-button {
  right: 0px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 24px;
}
.prev-button-modal {
  /* left: 10px; */
  /* left: 10px; */
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 30px !important;
  /* padding: 10px !important; */
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 5px !important;
}
.next-button-modal {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 30px !important;
  /* padding: 10px !important; */
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 5px !important;
}
.sliderStyle {
  position: relative;
}

.slider-thumbnail {
  margin-top: 12px;
}

.swiper-button-prev,
.swiper-button-next,
.slides.propertySerarchSlide button.slick-arrow,
.slick-slider.slick-initialized button.slick-arrow {
  background-color: #54595f;
  height: 40px;
  width: 25px;
  z-index: 9;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1;
}

.swiper-button-next:after,
.slides.propertySerarchSlide button.slick-arrow.slick-next:before,
.slick-slider.slick-initialized button.slick-arrow.slick-next:before {
  content: "»" !important;
  background: transparent;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 24px;
}

.swiper-button-prev:after,
.slides.propertySerarchSlide button.slick-arrow.slick-prev:before,
.slick-slider.slick-initialized button.slick-arrow.slick-prev:before {
  content: "«" !important;
  background: transparent;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 24px;
}

.pagination-dots {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  align-items: center;
  flex-wrap: wrap; /* Allow pagination dots to wrap to the next line */
  max-width: 650px; /* Set maximum width to control when wrapping occurs */
}

.dot {
  height: 9px;
  width: 9px;
  margin: 5px; /* Increase margin to create more spacing */
  background-color: #bbb;
  border-radius: 100%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #54595f;
}

.MuiFormLabel-root label {
  background: #fff !important;
}

label#demo-simple-select-label {
  font-family: Montserrat, sans-serif;
  background: #fff !important;
}

.c_1_1.light svg {
  fill: #65666a;
  width: 20px;
  margin-top: -4px;
}
.modf_def_cont_block {
  display: flex;
  flex-wrap: wrap;
}

.videoinfo {
  overflow: hidden;

  width: 100%;
}

@media (max-width: 1199px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 14px;
  }
  .navbar-nav li.nav-item {
    margin: 6px;
  }
}

@media (max-width: 991px) {
  .modf_def_cont_block {
    display: block !important;
    flex-wrap: initial;
    padding-left: 12px;
  }
  .header_top {
    padding: 0px 15px;
    justify-content: space-between !important;
  }
  .ref_search_opt {
    flex: 1 !important;
  }

  .header_top {
    padding: 0px 15px;
  }

  .container.for-small-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
  section.header-wrapper {
    padding: 10px 0px;
  }
  button.navbar-toggler {
    background: #f49d00;
    color: #fff;
    border: 0px;
    padding: 6px 7px 4px;
    border-radius: 5px;
  }
  /* .header_top .ref_search_opt {
        display: none;
    } */
  /* .header_top {
        position: absolute;
        right: 70px;
        top: 25px;
    } */
  .css-ece9u5 {
    min-width: 103px !important;
  }
  button.navbar-toggler svg {
    width: 30px;
    height: 37px;
  }
  .ref_search_opt .material-textfield {
    margin: 0px;
  }
  .ref_search_opt button.btn.btn-default {
    right: 0px;
    height: 45px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .header_top p {
    padding-left: 4px;
    min-width: 110px;
  }

  .collapse.navbar-collapse {
    position: fixed;
    width: 276px;
    z-index: 100;
    background: #fff;
    right: 0px;
    top: 0px;
    height: 100vh;
    transform: translate(300px, 0px);
    transition: all 0.3s ease;
  }
}

@media (max-width: 767px) {
  .ref_search_opt {
    display: flex;
    margin-right: 11px;
    position: relative;
    /* width: 133px; */
    position: relative;
    z-index: 10;
  }
  /* .header_top {
        position: absolute;
        right: 63px;
        top: 23px;
    } */
  .inprow_opr2 {
    flex-direction: column;
  }
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 16px !important;
  }
  .material-textfield2 {
    padding: 0px 0px 10px 0;
  }
  /* .header_top {
        position: absolute;
        right: 55px;
        top: 23px;
        z-index: 10;
    } */
  button.navbar-toggler {
    padding: 6px 4px 4px;
    border-radius: 5px;
  }
  .inp_row_block_wrap {
    flex-direction: column;
  }
  .inp_row_block_wrap .inp_row_block {
    flex: 100%;
    width: 100%;
  }
  .heading,
  .heading > * {
    font-size: 27px !important;
  }
}
@media (max-width: 400px) {
  img.img-fluid.brand-logo {
    width: 132px;
  }
  p.favorites_opt {
    /* position: absolute;
		top: 67px;
		z-index: 10;
		z-index: 10px;
		right: 61px; */
  }
}

.navbar-toggler.menu-button {
  background: transparent;
  color: #000;
}

@media (max-width: 900px) {
  .nav-heading {
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 15px;
  }

  .vis {
    display: none;
  }
  .navbar-nav li.nav-item:hover ul.nav.drop-menu {
    display: block;
    height: 10px !important;
  }
  .nav-link-drop {
    font-size: 13px !important;
    font-weight: 300 !important;
    padding-left: 3px;
  }
  .navbar-nav ul.nav.drop-menu {
    position: relative;
    /* background: #fff; */
    padding: 0px !important;
    /* font-size: 16px; */
    display: unset !important;
  }
  ul.topMenu li.nav-item:nth-last-child(1) a ~ ul.nav.drop-menu {
    /* left: auto;
		right: 0; */
    text-align: left;
  }
  /*
	.nav.drop-menu {
	  display: block;
	  padding-left: 20px; 
	 } 

	
	.navbar-nav .nav-item img {
	  display: none;
	}
  

	.navbar-nav .nav-item {
	  display: block;
	  margin-bottom: 10px;
	}
   */
  .navbarcontent_des {
    max-height: 100vh;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  .navbarcontent_des::-webkit-scrollbar {
    width: 8px;
  }

  .navbarcontent_des::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .navbarcontent_des::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }
}

.MenuShow {
}

.margin-tops {
  margin-top: 20px;
}

.button-style {
  border: 2px solid;
  padding: 10px;
  text-align: center;
  min-width: 150px;
}
