.swiper-button-next,
.swiper-button-prev {
  top: 50%;
  transform: translateY(-50%);
}

.search-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50px;
  padding: 5px 15px;
  width: 100%; /* Adjust width to fit container */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

@media (max-width: 600px) {
  /* Mobile view specific styling */
  .search-container {
    width: 100%; /* Full width for mobile */
  }
}

.search-container:hover {
  border-color: #999; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
   /* width: 80% */
}

/* .search-icon {
  font-family: 'Material Icons';
  content: '\e8b6'; 
  color: #888;
  font-size: 20px;
  margin-right: 10px;
} */

.search-input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  padding: 5px;
  background-color: transparent;
}

.search-input::placeholder {
  color: #bbb;
}

.search-input:focus {
  box-shadow: none;
}
