/* CookieConsent.css */
.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 15px;
    text-align: center;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .cookie-message {
    font-size: 14px;
    margin-right: 20px;
  }
  
  .cookie-button {
    padding: 8px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: #808080; /* Green background for "OK" button */
    color: #fff;
    /* margin-top: 10px; For mobile devices, in case the text wraps */
    border: 1px solid white;
  }
  
  .cookie-button.ok:hover {
    opacity: 0.9;
  }
  