.filter-chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
}

.chip {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #adadae;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 700;
}

.close-icon {
  margin-left: 5px;
  cursor: pointer;
}

.clear-filters-button {
  padding: 5px 20px;
  background-color: #CAA274;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: auto;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
}